import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NbToastrService } from '@nebular/theme';
import { ClassifiersService, AuthorityService } from '@amdb/drone/@core/services';
import { Classifier, Authority } from '@amdb/drone/shared/models';
import { FormGroup } from '@angular/forms';
import { AuthorityDataGroup } from '../../models/authority-data-group.model';

@Component({
    selector: 'amdb-upsert-authority',
    templateUrl: './upsert-authority.component.html',
    styleUrls: ['./upsert-authority.component.scss']
})
export class UpsertAuthorityComponent implements OnInit {

    @Input()
    authority: Authority;

    @Output()
    onAuthorityRegisterd: EventEmitter<Authority> = new EventEmitter();

    authorityRoles: Classifier[];
    dataGroups: Classifier[];
    submitted: boolean = false;
    editMode: boolean;

    switcherFirstValueLabel = 'Nav';
    switcherSecondValueLabel = 'Ir';

    constructor(private classifiersService: ClassifiersService,
        private authorityService: AuthorityService,
        private toastrService: NbToastrService) { }

    async ngOnInit(): Promise<void> {
        if (this.authority) {
            this.editMode = true;
        } else {
            this.authority = {} as Authority;
            this.authority.isAutoSubmitter = false;
            this.authority.isSubmitter = false;
            this.authority.exportHistoryViewer = false;
        }
        this.authorityRoles = await this.classifiersService.getAuthorityRoles();
        this.dataGroups = await this.classifiersService.getDataGroups();
    }

    async upsertAuthority(form: FormGroup): Promise<void> {
        this.submitted = true;
        try {
            if (this.editMode) {
              await this.authorityService.updateAuthority(this.authority);
              this.toastrService.success('Institūcijas dati atjaunoti', 'Info');
              this.onAuthorityRegisterd.emit(this.authority);
            } else {
                await this.authorityService.registerAuthority(this.authority);
                this.authority = {} as Authority;
                form.reset();
                this.onAuthorityRegisterd.emit(this.authority);
                this.toastrService.success('Institūcija reģistrēta', 'Info');
            }
            this.submitted = false;
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                errorResponse.error.errors.forEach((err) => {
                    this.toastrService.danger(err, 'Kļūda');
                });
            }
            this.submitted = false;
        }
    }

    toggleIsAutoSubmitter(){
        this.authority.isAutoSubmitter = !this.authority.isAutoSubmitter;

        // if authority is being created, and auto submitter is turned off and submitter is not turned on, remove DataGroups
        if(!this.authority.isAutoSubmitter && !this.authority.id && !this.authority.isSubmitter) {
            this.authority.dataGroupAuthorities = null;
            this.authority.dataGroupIds = null;
        }

        // if authority is being created, and auto submitter is turned off, remove CAA prefix
        if(!this.authority.isAutoSubmitter && !this.authority.id) {
            this.authority.caaPrefix = null;
        }
    }

    toggleExportHistoryViewer() {
        this.authority.exportHistoryViewer = !this.authority.exportHistoryViewer;
    }

    toggleIsSubmitter() {
        this.authority.isSubmitter = !this.authority.isSubmitter;

        // if authority is being created, and submitter is turned off and auto submitter is not turned od, remove DataGroups
        if(!this.authority.isSubmitter && !this.authority.id && !this.authority.isAutoSubmitter) {
            this.authority.dataGroupAuthorities = null;
            this.authority.dataGroupIds = null;
        }
    }

    updateDataGroups() {
        let existingDataGroupIds = [];
        if(!this.authority.dataGroupAuthorities) {
            this.authority.dataGroupAuthorities = [] as AuthorityDataGroup[];
        }
        this.authority.dataGroupAuthorities.forEach((dataGroup) => {
          existingDataGroupIds.push(dataGroup.dataGroupId);
        });
        let removedDataGroup = existingDataGroupIds.filter(item => this.authority.dataGroupIds.indexOf(item) < 0);
        let addedDataGroup = this.authority.dataGroupIds.filter(item => existingDataGroupIds.indexOf(item) < 0);

        if(addedDataGroup.length > 0) {
          let dataGroup = {} as AuthorityDataGroup;
          dataGroup.authorityId = this.authority.id;
          dataGroup.dataGroupId = addedDataGroup[0];
          this.authority.dataGroupAuthorities.push(dataGroup);
        }

        if(removedDataGroup.length > 0) {
          this.authority.dataGroupAuthorities =  this.authority.dataGroupAuthorities.filter(dataGroupAuthority => dataGroupAuthority.dataGroupId != removedDataGroup[0]);
        }
    }
}
