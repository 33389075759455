import { CommandResult } from '@amdb/drone/shared/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Region } from '../../pages/regions/region.model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpClient: HttpClient) { }

  addRegion(newRegion: Region): Promise<CommandResult<boolean>> {
    return this.httpClient.post<CommandResult<boolean>>('/api/v1/regions', newRegion).toPromise();
  }

  editRegion(editedRegion: Region): Promise<CommandResult<boolean>> {
    return this.httpClient.post<CommandResult<boolean>>('/api/v1/regions/edit', editedRegion).toPromise();
  }

  getRegions(): Promise<Region[]> {
    return this.httpClient.get<Region[]>('/api/v1/regions/all').toPromise();
  }
}
