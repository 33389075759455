import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { DailyPeriod, SubmissionDetails, TimePeriod, UasZoneVersionAuthority, UasZoneVersionReason } from '../submission.model';
import { Classifier } from '@amdb/drone/shared/models';
import { ClassifiersService } from '@amdb/drone/@core/services';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { ToFlyAuthoritiesService } from '@amdb/drone/shared/components/authority-contacts/to-fly-authorities.service';
import { ToFlyAuthority } from '@amdb/drone/shared/models/to-fly-authority.model';
import { NbAuthToken, NbTokenService } from '@nebular/auth';
import { AmdbAccessCheckerService } from '@amdb/drone/@core/security/amdb-access-checker.service';
import { parseISO } from 'date-fns';
import { Region } from '../../regions/region.model';
import { RegionService } from '../../regions/region.service';
import { NgForm } from '@angular/forms';
import { InfoDialogComponent } from '@amdb/drone/shared/components/info-dialog/info-dialog.component';

@Component({
    templateUrl: './add-submission.component.html',
    styleUrls: ['./add-submission.component.scss'],
})
export class AddSubmissionComponent implements OnInit {
    @ViewChild('form') form: NgForm;

    _submission: SubmissionDetails;
    @Input()
    set submission(val: SubmissionDetails) {
        this._submission = val;
        if (this._submission.reasonDate) {
            this._submission.reasonDate = <any>new Date(this._submission.reasonDate);
        }
    }
    get submission(): SubmissionDetails {
        return this._submission;
    }

    get timePeriodEndDateTimeRequired(): boolean {
        if (this.edit && this.editAfterApproval && this.submission.autoSubmitted && this.amdbAccessCheckerService.isAutoSubmitter(this.authToken.getPayload())) {
            return true;
        }
        return false;
    }

    @Input()
    edit: boolean;

    @Input()
    fromFile: boolean;

    @Input()
    hasGtseUuid: boolean = false;

    submissionPurposes: Classifier[];
    uasZoneVersionReasons: Classifier[];
    zoneTypes: Classifier[];
    regions: Region[];
    uomDimensions: Classifier[];
    weekdays: Classifier[];
    sunriseSunsetEvents: Classifier[];
    toFlyAuthorities: ToFlyAuthority[];

    authorityPurposes: Classifier[];
    firstAuthorityPurposes: Classifier[];
    restAuthorityPurposes: Classifier[];

    airspaceVolumeLimitVerticalReferences: Classifier[];
    airspaceVolumeCoordinateSystems: Classifier[];
    yesNo: Classifier[];
    uasZoneVersionRestrictions: Classifier[];
    elements: Classifier[];
    currentZoneReasons: Classifier[];
    authorization: string;

    restrictionConditionsArray: any[];
    restrictionConditionsIndex: number = 0;

    timePeriodArray: any[];
    timePeriodIndex: number;
    dailyPeriodIndex: number;

    toFlyAuthorityArray: any[];
    toFlyAuthorityIndex: number;

    otherReasonSelected: boolean = false;

    dataInitialized: boolean = false;
    allFields: boolean = true;
    editAfterApproval: boolean = false;
    //utcTime: boolean = true;

    authToken: NbAuthToken;

    constructor(
        protected ref: NbDialogRef<AddSubmissionComponent>,
        private classifiersService: ClassifiersService,
        private regionService: RegionService,
        private toFlyAuthorityService: ToFlyAuthoritiesService,
        private tokenService: NbTokenService,
        private amdbAccessCheckerService: AmdbAccessCheckerService,
        private dialogService: NbDialogService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    async ngOnInit(): Promise<void> {
        this.authToken = await this.tokenService.get().toPromise();
        if (this.edit && this.submission.restrictionAreaHistory.some(rah => rah.statusId === 805)) {
            this.editAfterApproval = true;
            if (this.submission.autoSubmitted) {
                // If submission is auto submitted
                if (this.amdbAccessCheckerService.canEditAll(this.authToken.getPayload())) {
                    // If user has Importer, Publisher or AutoSubmitter role
                    this.allFields = true;
                } else {
                    // If user only has regular submitter role
                    this.allFields = false;
                }
            } else {
                // If submission is not auto submitted
                if (this.amdbAccessCheckerService.isImporter(this.authToken.getPayload()) || this.amdbAccessCheckerService.isPublisher(this.authToken.getPayload())) {
                    // If user has Importer role or Publisher role
                    this.allFields = true;
                } else {
                    // All other roles
                    this.allFields = false;
                }
            }
        } else {
            // If it is not edit after submission is CAA_APPROVED
            this.allFields = true;
        }

        this.restrictionConditionsIndex = 0;
        this.restrictionConditionsArray = [] as any;

        this.timePeriodIndex = 0;
        this.timePeriodArray = [] as any;

        this.dailyPeriodIndex = 0;

        this.toFlyAuthorityArray = [] as any;
        this.toFlyAuthorityIndex = 0;

        if (!this.fromFile) {
            this.submission.uasZoneVersion.airspaceVolume.coordinateSystemId = 702;
        }

        this.submissionPurposes = await this.classifiersService.getSubmissionPurposes();
        this.uasZoneVersionReasons = await this.classifiersService.getUasZoneVersionReasons();
        this.zoneTypes = await this.classifiersService.getZoneTypes();
        this.regions = await this.regionService.getRegions();
        this.uomDimensions = await this.classifiersService.getUomDimensions();
        this.weekdays = await this.classifiersService.getWeekDays();
        this.sunriseSunsetEvents = await this.classifiersService.getSunriseSunsetEvents();
        this.authorityPurposes = await this.classifiersService.getAuthorityPurposes();
        this.airspaceVolumeLimitVerticalReferences = await this.classifiersService.getAirspaceVolumeLimitVerticalReferences();
        this.airspaceVolumeCoordinateSystems = await this.classifiersService.getAirspaceVolumeCoordinateSystems();
        this.yesNo = await this.classifiersService.getYesNo();
        this.uasZoneVersionRestrictions = await this.classifiersService.getUasZoneVersionRestrictions();
        this.elements = await this.classifiersService.getElements();

        // Needed for adding the zone
        this.toFlyAuthorities = await this.toFlyAuthorityService.getAllToFlyAuthoritiesWithoutAuthorityId();
        // Needed for cases, when user which has not added zone, to edit it, for example - CAA.
        if (this.toFlyAuthorities.length == 0) {
            this.toFlyAuthorities = await this.toFlyAuthorityService.getAllToFlyAuthorities(this.submission.uasZoneVersion.uasZoneVersionAuthorities[0].toFlyAuthority.authorityId);
        }

        // Set values of restriction conditions, reasons, timePeriod and authority to display in edit modal
        if (this.edit == true) {
            // RestrictionConditions
            if (this.submission.uasZoneVersion.restrictionConditions != undefined) {
                let currentRestrictionConditions = JSON.parse(this.submission.uasZoneVersion.restrictionConditions);
                currentRestrictionConditions.forEach(condition => {
                    this.restrictionConditionsArray.push({ id: this.restrictionConditionsIndex, value: condition });
                    this.restrictionConditionsIndex++;
                });
            }

            // Reasons
            this.currentZoneReasons = [] as Classifier[];
            this.submission.uasZoneVersion.uasZoneVersionReasons.forEach(element => {
                this.currentZoneReasons.push(this.uasZoneVersionReasons.find(reason => reason.id == element.reasonId));
            });

            if (this.submission.uasZoneVersion.uasZoneVersionReasons.find(r => r.reasonId == 509)) {
                this.otherReasonSelected = true;
            } else {
                this.otherReasonSelected = false;
            }

            // TimePeriods
            this.submission.uasZoneVersion.timePeriods.forEach(timePeriod => {
                timePeriod.startDateTime = <any>new Date(timePeriod.startDateTime);
                if (timePeriod.endDateTime) {
                    timePeriod.endDateTime = <any>new Date(timePeriod.endDateTime);
                }

                let schedulesForTimePeriod = [] as any;
                let dailyPeriodsFromJson = [] as DailyPeriod[];
                if (timePeriod.schedule != undefined) {
                    dailyPeriodsFromJson = JSON.parse(timePeriod.schedule);
                    dailyPeriodsFromJson.forEach(period => {
                        let splittedStartTime = period.startTime.split(':');
                        let splittedEndTime = period.endTime.split(':');
                        period.startTime = <any>(
                            new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                parseInt(splittedStartTime[0], 10),
                                parseInt(splittedStartTime[1], 10),
                                parseInt(splittedStartTime[2], 10),
                            )
                        );

                        period.endTime = <any>(
                            new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                parseInt(splittedEndTime[0], 10),
                                parseInt(splittedEndTime[1], 10),
                                parseInt(splittedEndTime[2], 10),
                            )
                        );
                        schedulesForTimePeriod.push({ id: this.dailyPeriodIndex, schedule: period });
                        this.dailyPeriodIndex++;
                    });
                }

                this.timePeriodArray.push({ id: this.timePeriodIndex, timePeriod: timePeriod, schedules: schedulesForTimePeriod, scheduleDaysOverlap: false });
                this.checkScheduleDays(this.timePeriodIndex);
                this.timePeriodIndex++;
            });

            // Authorities
            this.submission.uasZoneVersion.uasZoneVersionAuthorities.forEach(authority => {
                let daysAndHours;
                let hoursMinutes;

                if (authority.purposeId != 1403) {
                    // read intervalBefore value only if authority is not with INFORMATION purpose
                    if (authority.intervalBefore.indexOf('.') != -1) {
                        // <= has days
                        daysAndHours = authority.intervalBefore.split('.');
                        hoursMinutes = daysAndHours[1].split(':');
                        hoursMinutes = hoursMinutes.map(hm => parseInt(hm, 10));

                        this.toFlyAuthorityArray.push({ id: this.toFlyAuthorityIndex, days: daysAndHours[0], hours: hoursMinutes[0], minutes: hoursMinutes[1], toFlyAuthority: authority });
                        this.toFlyAuthorityIndex++;
                    } else {
                        // <= does not have days
                        hoursMinutes = authority.intervalBefore.split(':');
                        hoursMinutes = hoursMinutes.map(hm => parseInt(hm, 10));

                        this.toFlyAuthorityArray.push({ id: this.toFlyAuthorityIndex, days: 0, hours: hoursMinutes[0], minutes: hoursMinutes[1], toFlyAuthority: authority });
                        this.toFlyAuthorityIndex++;
                    }
                } else {
                    this.toFlyAuthorityArray.push({ id: this.toFlyAuthorityIndex, days: 0, hours: 0, minutes: 0, toFlyAuthority: authority });
                    this.toFlyAuthorityIndex++;
                }
            });
            this.updatePossibleAuthorityPurposes();
            this.updateConditionalReasonRestAuthorityPurposes();
        } else {
            const submissionDate = new Date();
            this.submission.uasZoneVersion.name = `Objekts-${submissionDate.getDate()}-${submissionDate.getMonth() + 1}-${submissionDate.getFullYear()}`;
            this.submission.uasZoneVersion.regionId = null;
            this.submission.uasZoneVersion.typeId = 201;
            this.submission.uasZoneVersion.restrictionId = 302;

            this.submission.uasZoneVersion.airspaceVolume.uomDimensionsId = 1201;
            this.submission.uasZoneVersion.airspaceVolume.lowerLimit = 0;
            this.submission.uasZoneVersion.airspaceVolume.lowerVerticalReferenceId = 601;
            this.submission.uasZoneVersion.airspaceVolume.upperLimit = 120;
            this.submission.uasZoneVersion.airspaceVolume.upperVerticalReferenceId = 601;
            this.submission.uasZoneVersion.airspaceVolume.horizontalLimit = 0;

            this.submission.reasonNr = `${submissionDate.getMonth() + 1}-${submissionDate.getDate()}`;
            this.submission.reasonDate = <any>new Date(submissionDate.toDateString());
            this.submission.uasZoneVersion.description = `Objekts-${submissionDate.getDate()}-${submissionDate.getMonth() + 1}-${submissionDate.getFullYear()}`;
            this.submission.purposeId = 901;
            this.submission.elementId = 1105;
            this.submission.flightProcedureExistsId = 402;
            this.submission.consultingId = 402;
            this.submission.safetyId = 402;
            this.submission.additionalElementId = 402;
            this.submission.uazImpactId = 402;
            this.submission.additionalElementId = 402;

            // Adding 1st required time period
            let timePeriod = {} as TimePeriod;
            timePeriod.startDateTime = <any>new Date(submissionDate.toDateString());
            timePeriod.endDateTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
            timePeriod.permanentId = 402;
            this.timePeriodArray.push({ id: this.timePeriodIndex, timePeriod: timePeriod, schedules: [], scheduleDaysOverlap: false });
            this.timePeriodIndex++;

            // Adding 1st required authority
            let toFlyAuthority = {} as UasZoneVersionAuthority;
            if (this.toFlyAuthorities.length == 1) {
                toFlyAuthority.toFlyAuthorityId = this.toFlyAuthorities[0].id;
            }
            toFlyAuthority.purposeId = 1401;
            this.toFlyAuthorityArray.push({ id: this.toFlyAuthorityIndex, days: 5, hours: 0, minutes: 0, toFlyAuthority: toFlyAuthority });
            this.toFlyAuthorityIndex++;

            this.updatePossibleAuthorityPurposes();
            this.updateConditionalReasonRestAuthorityPurposes();
        }

        this.dataInitialized = true;
    }

    setReasons(selectedReasons): void {
        this.submission.uasZoneVersion.uasZoneVersionReasons = [] as UasZoneVersionReason[];
        selectedReasons.forEach(reason => {
            var zoneReason = {} as UasZoneVersionReason;
            zoneReason.reasonId = reason.id;
            this.submission.uasZoneVersion.uasZoneVersionReasons.push(zoneReason);
        });

        if (this.submission.uasZoneVersion.uasZoneVersionReasons.find(r => r.reasonId == 509)) {
            this.otherReasonSelected = true;
            this.submission.uasZoneVersion.otherReasonInfo = this.submission.uasZoneVersion.uasZoneVersionReasons.find(r => r.reasonId == 509).otherReasonInfo;
        } else {
            this.otherReasonSelected = false;
        }
    }

    updatePossibleAuthorityPurposes() {
        switch (this.submission.uasZoneVersion.restrictionId) {
            case 301:
            case 304: {
                this.firstAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1403);
                this.restAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1403);

                this.toFlyAuthorityArray.forEach(authority => (authority.toFlyAuthority.purposeId = 1403));
                break;
            }

            case 302: {
                this.firstAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1401);
                this.restAuthorityPurposes = this.authorityPurposes;

                this.toFlyAuthorityArray[0].toFlyAuthority.purposeId = 1401;
                break;
            }

            case 303: {
                this.firstAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1402 || purpose.id == 1403);
                this.restAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1402 || purpose.id == 1403);

                this.toFlyAuthorityArray.forEach(authority => {
                    authority.toFlyAuthority.purposeId = undefined;
                });
                break;
            }
        }
    }

    updateConditionalReasonRestAuthorityPurposes() {
        if (this.submission.uasZoneVersion.restrictionId != 303) {
            return;
        }

        switch (this.toFlyAuthorityArray[0].toFlyAuthority.purposeId) {
            case 1402: {
                this.restAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1402 || purpose.id == 1403);

                // update all other authorities purposes (except the first one), to change them in cases, when previously there was another purpose selected, rather than 1402 or 1403
                this.toFlyAuthorityArray.forEach((authority, index) => {
                    if (index > 0) {
                        authority.toFlyAuthority.purposeId = undefined;
                    }
                });
                break;
            }

            case 1403: {
                this.restAuthorityPurposes = this.authorityPurposes.filter(purpose => purpose.id == 1403);

                // update all other authorities purposes (except the first one), to change them in cases, when previously there was another purpose selected, rather than 1403
                this.toFlyAuthorityArray.forEach((authority, index) => {
                    if (index > 0) {
                        authority.toFlyAuthority.purposeId = 1403;
                    }
                });
                break;
            }
        }
    }

    addRestrictionConditionField() {
        this.restrictionConditionsArray.push({ id: this.restrictionConditionsIndex, value: '' });
        this.restrictionConditionsIndex++;
    }

    removeRestrictionConditionField(index: number) {
        this.restrictionConditionsArray.splice(index, 1);
    }

    addTimePeriod() {
        let timePeriod = {} as TimePeriod;
        timePeriod.permanentId = 401;
        if (this.submission.isSunsetSunriseZone) {
            // if zone is marked as sunrise / sunset zone, when adding new time period, default schedule must be added too
            let dailyPeriod = {} as DailyPeriod;
            dailyPeriod.startEvent = this.timePeriodArray[0].schedules[0].schedule.startEvent;
            dailyPeriod.endEvent = this.timePeriodArray[0].schedules[0].schedule.endEvent;
            dailyPeriod.startTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
            dailyPeriod.endTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
            this.dailyPeriodIndex++;
            this.timePeriodArray.push({ id: this.timePeriodIndex, timePeriod: timePeriod, schedules: [{ id: this.dailyPeriodIndex, schedule: dailyPeriod }], scheduleDaysOverlap: false });
        } else {
            this.timePeriodArray.push({ id: this.timePeriodIndex, timePeriod: timePeriod, schedules: [], scheduleDaysOverlap: false });
        }
        this.timePeriodIndex++;
    }

    removeTimePeriod(index: number) {
        this.timePeriodArray.splice(index, 1);
    }

    addSchedule(timePeriodIndex: number) {
        let dailyPeriod = {} as DailyPeriod;
        if (this.submission.isSunsetSunriseZone) {
            // if zone isSunsetSunriseZone, add default start / end time values
            dailyPeriod.startTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
            dailyPeriod.endTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);

            // set all next schedules start event and end event to be same as the first schedules start event and end event, because at this time
            // start event and end event can be only equal across all schedules
            if (this.timePeriodArray[0].schedules.length > 0) {
                dailyPeriod.startEvent = this.timePeriodArray[0].schedules[0].schedule.startEvent;
                dailyPeriod.endEvent = this.timePeriodArray[0].schedules[0].schedule.endEvent;
            }
        }
        this.timePeriodArray[timePeriodIndex].schedules.push({ id: this.dailyPeriodIndex, schedule: dailyPeriod });
        this.dailyPeriodIndex++;
    }

    removeSchedule(timePeriodIndex: number, scheduleIndex: number) {
        this.timePeriodArray[timePeriodIndex].schedules.splice(scheduleIndex, 1);
        this.checkScheduleDays(timePeriodIndex);
    }

    // checks if there is overlapping days in any of time periods schedules
    checkScheduleDays(timePeriodIndex: number) {
        let overlaps: boolean[] = [];
        let timePeriod = this.timePeriodArray[timePeriodIndex];

        if (timePeriod.schedules.length > 1) {
            // does the check only if there is more than one schedule
            timePeriod.schedules.forEach((checkableSchedule, i) => {
                timePeriod.schedules.forEach((element, j) => {
                    if (i != j) {
                        // do not check equal
                        if (checkableSchedule.schedule.day && element.schedule.day) {
                            if (this.containsAny(element.schedule.day, checkableSchedule.schedule.day)) {
                                overlaps.push(true);
                            } else {
                                overlaps.push(false);
                            }
                        }
                    }
                });
            });
        } else {
            overlaps.push(false);
        }

        if (overlaps.includes(true)) {
            timePeriod.scheduleDaysOverlap = true;
        } else {
            timePeriod.scheduleDaysOverlap = false;
        }
    }

    containsAny(source, target) {
        var result = source.filter(item => {
            return target.indexOf(item) > -1;
        });
        return result.length > 0;
    }

    modifySchedules() {
        // only one schedule should be available, if zone isSunsetSunriseZone
        // nb-checkbox checkedChange gets triggered before actual value is changed,
        // so the condition for removing schedules is opposite (gets triggered on false)
        if (this.submission.isSunsetSunriseZone == false) {
            this.timePeriodArray.forEach(element => {
                if (element.schedules.length != 0) {
                    // check, if any schedules are added
                    element.schedules = element.schedules.splice(0, 1);
                    element.schedules[0].schedule.startTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
                    element.schedules[0].schedule.endTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
                    element.schedules[0].schedule.startEvent = null;
                    element.schedules[0].schedule.endEvent = null;
                } else {
                    // if zone is marked as sunrise / sunset zone, at least one schedule must be added to time period, so that start / end events are set
                    let dailyPeriod = {} as DailyPeriod;
                    dailyPeriod.startTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
                    dailyPeriod.endTime = <any>new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
                    element.schedules.push({ id: this.dailyPeriodIndex, schedule: dailyPeriod });
                    this.dailyPeriodIndex++;
                }
            });
        } else {
            this.timePeriodArray.forEach(element => {
                if (element.schedules.length > 0) {
                    // check, if any schedules are added
                    // remove default start / end times defined, if zone was checked as sunset sunrise zone
                    element.schedules.forEach(scheduleElement => {
                        scheduleElement.schedule.startTime = null;
                        scheduleElement.schedule.endTime = null;
                        scheduleElement.schedule.startEvent = null;
                        scheduleElement.schedule.endEvent = null;
                    });
                }
            });
        }
    }

    // set all schedules start event and end event to be same as the changed schedules start event and end event, because at this time
    // start event and end event can be only equal across all schedules
    updateStartEndEvents(timePeriodIndex: number, updatedScheduleIndex: number, startEvent: boolean): void {
        let event: string;
        if (startEvent) {
            event = this.timePeriodArray[timePeriodIndex].schedules[updatedScheduleIndex].schedule.startEvent;
        } else {
            event = this.timePeriodArray[timePeriodIndex].schedules[updatedScheduleIndex].schedule.endEvent;
        }

        this.timePeriodArray.forEach(timePeriod => {
            if (timePeriod.schedules.length == 0) {
                return;
            }
            timePeriod.schedules.forEach(scheduleElement => {
                if (startEvent) {
                    scheduleElement.schedule.startEvent = event;
                } else {
                    scheduleElement.schedule.endEvent = event;
                }
            });
        });
    }

    checkPeriodEndDate(timePeriodIndex: number) {
        // timeout needed, because nb-datepicker dateChange event fires before the value is actually changed in component
        setTimeout(() => {
            if (this.timePeriodArray[timePeriodIndex].timePeriod.endDateTime == null) {
                this.timePeriodArray[timePeriodIndex].timePeriod.permanentId = 401;
            } else {
                this.timePeriodArray[timePeriodIndex].timePeriod.permanentId = 402;
            }
        });
    }

    addToFlyAuthority() {
        let toFlyAuthority = {} as UasZoneVersionAuthority;

        switch (this.submission.uasZoneVersion.restrictionId) {
            case 301:
            case 304: {
                toFlyAuthority.purposeId = 1403;
                break;
            }

            case 303: {
                if (this.toFlyAuthorityIndex + 1 > 0 && this.toFlyAuthorityArray[0].toFlyAuthority.purposeId == 1403) {
                    toFlyAuthority.purposeId = 1403;
                }
            }
        }
        this.toFlyAuthorityArray.push({ id: this.toFlyAuthorityIndex, days: 5, hours: 0, minutes: 0, toFlyAuthority: toFlyAuthority });
        this.toFlyAuthorityIndex++;
    }

    removeToFlyAuthority(index: number) {
        this.toFlyAuthorityArray.splice(index, 1);
    }

    submitSubmission(): void {
        if (this.edit && this.form.pristine) {
            this.ref.close();
        }

        if (this.submission.uasZoneVersion.uasZoneVersionReasons.find(r => r.reasonId == 509)) {
            this.submission.uasZoneVersion.uasZoneVersionReasons.find(r => r.reasonId == 509).otherReasonInfo = this.submission.uasZoneVersion.otherReasonInfo;
        }

        let conditions: string[] = [];
        this.restrictionConditionsArray.forEach(element => {
            conditions.push(element.value);
        });

        let timePeriods: TimePeriod[] = [];
        this.timePeriodArray.forEach(element => {
            let schedules: DailyPeriod[] = [];

            element.timePeriod.startDateTime = format(<any>element.timePeriod.startDateTime, 'yyyy-MM-dd HH:mm:ss');
            if (isValid(element.timePeriod.endDateTime)) {
                element.timePeriod.endDateTime = format(<any>element.timePeriod.endDateTime, 'yyyy-MM-dd HH:mm:ss');
            }

            element.schedules.forEach(element => {
                element.schedule.startTime = format(<any>element.schedule.startTime, 'HH:mm:ss');
                element.schedule.endTime = format(<any>element.schedule.endTime, 'HH:mm:ss');

                schedules.push(element.schedule);
            });
            if (schedules != null) {
                element.timePeriod.schedule = JSON.stringify(schedules);
            } else {
                element.timePeriod.schedule = null;
            }

            timePeriods.push(element.timePeriod);
        });
        this.submission.uasZoneVersion.timePeriods = timePeriods;

        this.submission.uasZoneVersion.restrictionConditions = JSON.stringify(conditions);

        let authorities: UasZoneVersionAuthority[] = [];
        this.toFlyAuthorityArray.forEach(authority => {
            if (authority.toFlyAuthority.purposeId == 1403) {
                authority.toFlyAuthority.intervalBefore = null;
            } else {
                authority.toFlyAuthority.intervalBefore = authority.days + '.' + authority.hours + ':' + authority.minutes;
            }
            authorities.push(authority.toFlyAuthority);
        });

        this.submission.uasZoneVersion.uasZoneVersionAuthorities = authorities;

        this.submission.reasonDate = format(<any>this.submission.reasonDate, 'yyyy-MM-dd HH:mm:ss');
        this.ref.close(this.submission);
    }

    dismiss() {
        this.ref.close();
    }

    getFormControlByName(formControlName: string) {
        if (this.form) {
            return this.form.controls[formControlName];
        }
    }
}
