import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CoreModule } from './@core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

import { environment } from '@env/environment';
import { ThemeModule } from './@theme/theme.module';
import { ActivatedRouteSnapshot, DetachedRouteHandle, Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { SharedModule } from './shared';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { AddUserComponent } from './pages/admin/authorities/add-user/add-user.component';
import { ShpArchivePickerComponent } from './pages/submissions/shp-archive-picker/shp-archive-picker.component';
import { StatusChangeDialogComponent } from './pages/submissions/status-change-dialog/status-change-dialog.component';
import { GeometryPrimitiveValidationResultListComponent } from './pages/submissions/geometry-primitive-validation-result-list/geometry-primitive-validation-result-list.component';
import { MapModalComponent } from './shared/components/map-modal/map-modal.component';
import { AddSubmissionComponent } from './pages/submissions/add-submission/add-submission.component';
import { AddGroupComponent } from './pages/admin/data-groups/add-group/add-group.component';
import { BaseLayerDetailsComponent } from './pages/admin/base-layers/base-layer-details/base-layer-details.component';
import { HelpDialogComponent } from './shared/components/help-dialog/help-dialog.component';
import { TokenExpirationAlertService } from './@core/services/token-expiration-alert.service';

const ENTRY_COMPONENTS = [
    ConfirmDialogComponent,
    ShpArchivePickerComponent,
    StatusChangeDialogComponent,
    GeometryPrimitiveValidationResultListComponent,
    AddSubmissionComponent,
    AddGroupComponent,
    BaseLayerDetailsComponent,
    HelpDialogComponent,
    MapModalComponent
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent,
        ...ENTRY_COMPONENTS
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        TokenExpirationAlertService,
    ],
    entryComponents: [
        ...ENTRY_COMPONENTS
    ]
})
export class AppModule {
}

